@import '_variables.scss'
@import '_theme.scss'
@import '_mixin.scss'

@import '../../node_modules/bootstrap/scss/bootstrap'
@import '../../node_modules/bootstrap-vue/src/index.scss'

@import '_bootswatch.scss'

@import 'main.scss'
@import 'auth.scss'
@import 'ent.scss'
@import 'printer.scss'
@import 'vue-slider-theme.scss'

.focused-feedback-icon
  vertical-align: middle
  font-size: 1.8em

// Export some color variable to use it in Vue as JS variables
:export
  primary: $primary
  white: $white
