// Superhero 4.2.1
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #EBEBEB !default;
$gray-200: #4E5D6C !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #9965f4 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #d9534f !default;
$orange:  #f0ad4e !default;
$yellow:  #f0ad4e !default;
$green:   #5cb85c !default;
$teal:    #20c997 !default;
$cyan:    #5bc0de !default;

$primary:       $blue !default;
$secondary:     $gray-100 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         lighten($gray-200, 35%) !default;
$dark:          $gray-200 !default;

$yiq-contrasted-threshold:  185 !default;

// Body

$body-bg:                   #2B3E50 !default;
$body-color:                $gray-100 !default;

// Components

$border-radius:               0 !default;
$border-radius-lg:            0 !default;
$border-radius-sm:            0 !default;

// Fonts
$font-family-sans-serif:      "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$text-muted:                  rgba(255,255,255,.4) !default;

// Tables

$table-accent-bg:             rgba($white,.05) !default;
$table-hover-bg:              rgba($white,.075) !default;

$table-border-color:          rgba($black,.15) !default;

$table-head-bg:               $light !default;

$table-dark-bg:               $light !default;
$table-dark-border-color:     $gray-200 !default;
$table-dark-color:            $body-bg !default;

// Forms

$input-border-color:                transparent !default;

$input-group-addon-color:           $body-color !default;

$custom-file-button-color:          $white !default;
$custom-file-border-color:          $gray-200 !default;

// Dropdowns

$dropdown-bg:                       $gray-200 !default;
$dropdown-divider-bg:               var(--color-divider);

$dropdown-link-color:               $body-color !default;
$dropdown-link-hover-color:         $dropdown-link-color !default;
$dropdown-link-hover-bg:            $table-hover-bg !default;

// Navs

$nav-link-disabled-color:           rgba(255,255,255,.4) !default;

$nav-tabs-border-color:             $gray-200 !default;
$nav-tabs-link-active-color:        $body-color !default;
$nav-tabs-link-active-border-color: $gray-200 !default;

// Navbar

$navbar-padding-y:                  0.25rem !default;

$navbar-dark-color:                 rgba($white,.75) !default;
$navbar-dark-hover-color:           $white !default;

// Pagination

$pagination-color:                  $white !default;
$pagination-bg:                     $gray-200 !default;
$pagination-border-color:           transparent !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $nav-link-disabled-color !default;
$pagination-hover-border-color:     $pagination-border-color !default;

$pagination-disabled-color:         $nav-link-disabled-color !default;
$pagination-disabled-bg:            $pagination-bg !default;
$pagination-disabled-border-color:  $pagination-border-color !default;

// Modals

$modal-content-bg:                  $gray-200 !default;

$modal-header-border-color:         rgba(0,0,0,.2) !default;

// Cards

$card-cap-bg:                       $table-hover-bg !default;
$card-bg:                           $gray-200 !default;

// Popovers

$popover-bg:                        $gray-200 !default;

$popover-header-bg:                 $table-hover-bg !default;

// List group

$list-group-bg:                     $gray-200 !default;
$list-group-border-color:           transparent !default;

$list-group-hover-bg:               $nav-link-disabled-color !default;

$list-group-disabled-color:         $nav-link-disabled-color !default;

$list-group-action-color:           $white !default;
$list-group-action-hover-color:     $white !default;

// Breadcrumbs

$breadcrumb-divider-color:          $body-color !default;
$breadcrumb-active-color:           $body-color !default;

// Code

$pre-color:                         inherit !default;
