@import 'theme';
@import 'mixin';

table.pricing {
    border: 1px solid var(--color-divider);
    border-top: none;

    th {
        font-size: 1.2em;
    }
}

.user-subscription table {
    color: var(--color-text-primary);
}

.pricing {
    .btn.btn-primary {
        color: #fff;
    }

    .btn-group-toggle {
        background: var(--color-surface-secondary);
        .btn {
            border: solid thin var(--color-divider);

            &:not(.btn-primary) {
                color: var(--color-text-primary);
            }
        }
    }

    .price-matrix {
        div[class^="col-"] {
            padding: 0px;
            border-top: solid thin var(--color-divider);
            border-bottom: solid thin var(--color-divider);
            border-right: solid thin var(--color-divider);
            background: var(--color-surface-secondary);

            >div {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .price-header {
                height: 15rem;
                background: var(--color-surface-secondary);
                &.dh-pack-header {
                    height: 12rem;
                }

                .price-sub {
                    font-size: 0.95em;
                    font-weight: lighter;
                    margin-top: -0.1em;
                }
            }

            .price-action {
                height: 6rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-bottom: solid thin var(--color-divider);
                border-top: solid thin var(--color-divider);
            }

            .feature-header {
                margin-top: 2rem;
                height: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: var(--color-text-secondary);
            }

            .features {
                &.keyfeatures {
                    font-weight: bolder;
                }

                &.essentials {
                    font-weight: lighter;
                }

                &.dh-pack {
                    height: 14rem;
                    font-weight: lighter;
                }

                >div {
                    display: flex;
                    padding-top: 0.7rem;
                }

                i.fa-check {
                    padding-right: 0.7rem;
                    padding-top: 0.35rem;
                    color: var(--color-primary);
                }
            }
        }

        @include respond-above(sm) {
            >div:first-child {
                border-left: solid thin var(--color-divider);
            }
        }

        @include respond-below(md) {
            >div {
                border-left: solid thin var(--color-divider);
            }
        }

        .plan-name {
            font-size: 31px;
            line-height: 43px;
            padding-top: 1.5rem;
        }

        .plan-price {
            font-size: 31px;
            line-height: 43px;
            font-weight: bold;
            padding-top: 1.5rem;

            .price-unit {
                font-size: 18px;
                color: var(--color-text-secondary);
            }
        }
    }
}
.subcribe-dlg {
    font-weight: normal;

    .btn.btn-num-spinner {
        color: var(--color-text-primary);
    }

    .input-group {
        width: 7.5rem;
        border: solid thin var(--color-divider);
        button.btn-outline-primary {
            border-radius: 0px !important;
        }
    }

    label.custom-control-label {
        line-height: 25px;
    }
    th {
        font-size: 1.5em;
    }
    .btn-group-toggle {
        .btn {
            border: solid thin var(--color-divider);
            font-size: 0.9rem;
            box-shadow: none;

            &:not(.btn-primary) {
                color: var(--color-text-primary);
            }
        }
    }

    .custom-radio.recommended {
        .tag {
            display: inline-block;
            margin-left: 0.5em;
            background: var(--color-primary);
            color: var(--color-on-primary);
            border-radius: 4px;
            padding: 0px 5px;
        }
    }
}

.detective-hour-log {
    table {
        td {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 20em;
        }
    }
}
